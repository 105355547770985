<template>
  <main class="page">
    <section class="section section--auth c-reset c-auth">
      <div class="auth-container">
        <form @submit="submitPromoCode" novalidate>
          <div class="form__header mb-4">
            <div class="form__title">
              <h1 class="title title-lg">
                {{ $t('web.promo') }}
              </h1>
            </div>
          </div>
          <div class="form__body">
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :label="$t('web.promo')"
                  autocomplete="on"
                  v-model="inputPromoCode"
                  type="tex"
                ></c-input>
              </div>
            </div>
            <div class="form__group mt-3">
              <div class="form__item">
                <c-button
                  :value="$t('web.redeem_promo')"
                  :loading="cButtonDisabled"
                ></c-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import CInput from '@/components/Input'
import CButton from '@/components/Button'

export default {
  name: 'PromoCode',

  components: {
    CInput,
    CButton,
  },

  data() {
    return {
      cButtonDisabled: false,
      inputPromoCode: null,
    }
  },
  computed: {},
  mounted() {},
  methods: {
    submitPromoCode(event) {
      event?.preventDefault()
      this.redeemPromoCode()
    },
    redeemPromoCode() {
      this.cButtonDisabled = true

      this.$api.promo
        .redeemPromoCode(this.inputPromoCode)
        .then((d) => {
          this.$notify({
            group: 'message',
            type: 'success',
            duration: 4500,
            speed: 500,
            title: this.$t('web.promo-redeem_success'),
          })
          this.$router.push({ name: this.$ROUTER.NAME.AUTH.LOGIN })
          console.log({ d })
        })
        .catch((error) => {
          const {
            errors: { data: errorMessages },
          } = error

          console.log({ errorMessages })
          this.$notify({
            group: 'message',
            type: 'error',
            duration: 4500,
            speed: 500,
            title: this.$t('web.promo-redeem_error'),
            text: errorMessages.length > 0 ? errorMessages[0] : '',
          })
          console.log({ error })
        })
        .finally(() => {
          this.cButtonDisabled = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import './index';
</style>
